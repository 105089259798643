<template>
  <div>
    <button
      v-if="this.$route.params.uuid"
      class="btn btn-default btn-sm mt-4 px-5"
      @click="this.$router.go(-1)"
    >
      Back
    </button>
    <div class="row mt-1 mb-4">
      <div class="col-md-4">
        <div
          class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
          id="uploadImage"
        >
          <div class="card-body px-lg-5 py-lg-3">
            <div class="row">
              <div class="col-md-12">
                <h3>Upload Image</h3>
                <hr class="my-0 mb-3" />
                <div class="card p-1">
                  <h5>Existing Image</h5>
                  <hr class="my-0 mb-2" />
                  <img
                    v-if="data.iconImage"
                    :src="backend_url + data.iconImage"
                    alt=""
                    class="w-100"
                    style="height: 270px"
                  />
                  <span v-else class="text-muted text-center"
                    >No Existing Logo</span
                  >
                  <hr class="my-1 mb-2" />
                  <button
                    class="btn btn-danger"
                    v-if="data.iconImage"
                    @click="removeIconImage"
                  >
                    Remove
                  </button>
                </div>

                <drop-zone
                  :options="optionsImage"
                  @change="get_resource"
                  v-if="!data.iconImage"
                ></drop-zone>
                <span v-else>Remove the Existing image to upload new</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div
          class="card shadow-lg bg-gradient-secondary w-100 border-0 mb-0"
          id="resourcePreview"
        >
          <div class="card-body px-lg-5 py-lg-3">
            <div class="row">
              <div class="col-md-12">
                <h3>Preview</h3>
                <hr class="my-0 mb-3" />
                <img
                  class="card-img-top"
                  v-if="data.iconImage"
                  :src="backend_url + data.iconImage"
                  alt="resource image"
                  style="max-height:260px"
                />
                <p v-else class="text-center">
                  This Resource has no Image,please upload one
                </p>
                <h3 class="card-title mt-2">{{ data.title }}</h3>
                <p class="card-text" v-html="data.content"></p>
                <a style="color:#71A1A6;text-decoration:underline;" v-if="data.link_label && data.link_url" :href="data.link_url">{{
                  data.link_label
                }}</a>
                <!-- <p class="card-text">
                  <small class="text-muted"
                    >date added: {{ data.doneAt }}</small
                  >
                </p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 float-right">
      <router-link
        :to="{
          name: 'ResourceDetail',
          params: { uuid: this.$route.params.uuid },
        }"
      >
        <button
          type="button"
          class="btn btn-block btn-lg btn-default my-4 btn-md px-4"
        >
          <em class="ni ni-check-bold"></em>
          Done
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import Dropzoned from "../../components/DropzoneComp";
import axios from "axios";
import {alert} from "../../utils/alertUtils"
export default {
  name: "UploadImageResource",
  components: {
    "drop-zone": Dropzoned,
  },
  data() {
    return {
      data: {},
      backend_url: process.env.VUE_APP_BACKEND_URL,
      resourceImage: "",
      optionsImage: {
        id: "resourceImage",
        method: "put",
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
        upload_url: `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}/upload/image/`,
        maxFilesize: 1,
        maxFiles: 1,
        on_sending: function (file, xhr, form_data) {
          form_data.append("file", file);
          console.log("sending called");
        },
    
        on_complete: function (file) {
          if (file.status === "success") {
            file.response = JSON.parse(file.xhr.response);
            this.options.the.$emit("change");
          }
        },
      },
    };
  },
  methods: {
    get_resource: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          this.data.title = res.data.title;
          this.data.content = res.data.content;
          this.data.link_label = res.data.link_label;
          this.data.link_url = res.data.link_url;
          this.data.doneAt = res.data.doneAt;
          this.data.iconImage = res.data.iconImage;
          this.visible = res.data.visibility_status;
        })
        .catch(() => {
          alert.error(
            "An error occured please try again,if the error persist kindly contact the owner of this site!"
          );
        });
    },
    removeIconImage: function () {
      axios
        .put(
          `${process.env.VUE_APP_BACKEND_URL}/api/ressources/${this.$route.params.uuid}/image/Removal/`,{},
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.getToken}`,
            },
          }
        )
        .then(() => {
          alert.success_center("Image removed");
          this.get_resource();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.get_resource();
  },
};
</script>